import React from "react"
import styled from "styled-components"

const TextService = ({ icon, title, text, link }) => {
  return (
    <TextWrapper>
      <div className="service_info">
        <h3>
          <a href={link}>{title}</a>
        </h3>
        <p>{text}</p>
        <span className="service_icon">{icon}</span>
      </div>
    </TextWrapper>
  )
}

const TextWrapper = styled.article`
  margin: auto;

  .service_icon {
    justify-self: end;
    padding-right: 1rem;
    font-size: 2rem;
    font-weight: 200;
    color: var(--primaryColor);
  }

  .service_info {
    text-align: center;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  @media (min-width: 780px) {
    .service_info {
      h3 {
        font-size: 1.6rem;
        font-weight: 300;
      }
    }
  }
`

export default TextService
