import React from "react"
import { FaHardHat, FaFeatherAlt, FaAccusoft, FaImage } from "react-icons/fa"

export default [
  {
    icon: <FaAccusoft />,
    title: "Auf den folgenden Seiten ...",
    link: "/",
    text:
      "... werden wir Sie über unseren Betrieb, unsere Leistungen und unsere Tätigkeiten im Bereich Dach-, Wand- und Abdichtungstechnik informieren.",
  },
  {
    icon: <FaImage />,
    title: "Unter Firma ...",
    link: "/firma",
    text:
      "... informieren wir Sie über uns und unsere Firmengeschichte, damit Sie wissen mit wem Sie es zu tun haben.",
  },
  {
    icon: <FaHardHat />,
    title: "In unserem Leistungsangebot ...",
    link: "/leistungen",
    text:
      "... zeigen wir Ihnen, was wir für Sie tun können und welche Tätigkeitsfelder unsere Spezialität sind, wie zum Beispiel Schieferarbeiten und Bauklempnerei.",
  },
  {
    icon: <FaFeatherAlt />,
    title: "Haben wir Ihr Interesse geweckt ...",
    link: "/kontakt",
    text:
      "... oder haben Sie eine Frage zu uns oder zu einem Themenbereich? Gerne können Sie mit uns Kontakt aufnehmen.",
  },
]
