import React from "react"
import Title from "../Title"
import textstartseite from "../../constants/textstartseite"
import TextService from "./TextService"
import styled from "styled-components"

const Services = () => {
  return (
    <ServicesContainer>
      <Title title="Willkommen bei Paul Berenz " subtitle="Bedachungen!" />
      <hr className="services_separator" />

      <TextWrapper>
        {textstartseite.map((item, index) => (
          <TextService
            key={index}
            icon={item.icon}
            title={item.title}
            text={item.text}
            link={item.link}
          />
        ))}
      </TextWrapper>
    </ServicesContainer>
  )
}

const ServicesContainer = styled.section`
  padding-top: 3rem;

  .services_separator {
    width: 20%;
    margin: 0 auto;
  }
`

const TextWrapper = styled.div`
  margin-top: 1rem;
  padding: 1rem;

  @media (min-width: 780px) {
    padding: 4rem;
    margin-top: 4rem;
  }
`

export default Services
