import React from "react"
import Layout from "../components/Layout"
import StyledHero from "../components/StyledHero"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Carousel from "react-bootstrap/Carousel"
import "bootstrap/dist/css/bootstrap.min.css"
import Services from "../components/Home/Services"

const Index = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" description={data.site.siteMetadata.description} />
      <Carousel className="sliderstartseite">
        <Carousel.Item>
          <StyledHero
            home
            img={data.homeImage1.childImageSharp.fluid}
          ></StyledHero>
        </Carousel.Item>
        <Carousel.Item>
          <StyledHero
            home
            img={data.homeImage2.childImageSharp.fluid}
          ></StyledHero>
        </Carousel.Item>
      </Carousel>
      <Services />
    </Layout>
  )
}

export const getIndexData = graphql`
  query {
    homeImage1: file(relativePath: { eq: "bgslider/DSC_3217.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    homeImage2: file(relativePath: { eq: "bgslider/DSC_3215.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

export default Index
